import React, { forwardRef } from 'react';
import { motion, type MotionProps } from 'framer-motion';

type MotionDivProps = MotionProps & React.HTMLAttributes<HTMLDivElement>;

export const MotionDiv = forwardRef<HTMLDivElement, MotionDivProps>(
  (props, ref) => {
    return <motion.div ref={ref} {...props} />;
  },
);
