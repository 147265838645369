export class ValidationError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class ItemNotFoundError extends Error {
  constructor(message: string) {
    super(message);
  }
}
