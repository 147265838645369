'use client';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { ButtonLink } from '@reshima/navigation-ui';
import { useEffect, useState } from 'react';
import { Button } from '@reshima/pure-ui';

export type PreviousPageLinkProps = {
  href: string;
  ariaLabel: string;
  keepSearch?: boolean;
};

export function PreviousPageLink({
  href,
  ariaLabel,
  keepSearch,
}: PreviousPageLinkProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return (
      <Button
        className="p-1.5"
        ariaLabel={ariaLabel}
        tight
        circle
        ghost
        disabled
      >
        <MdOutlineKeyboardArrowRight className="text-2xl" />
      </Button>
    );
  }

  return (
    <ButtonLink
      href={href}
      keepSearch={keepSearch}
      ariaLabel={ariaLabel}
      className="p-1.5"
      tight
      circle
      ghost
    >
      <MdOutlineKeyboardArrowRight className="text-2xl" />
    </ButtonLink>
  );
}
