const charset =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';

export function randomString(length: number): string {
  if (length <= 0) {
    throw new Error('Length must be greater than 0');
  }

  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);

  const string = Array.from(randomValues)
    .map((byte) => charset[byte % charset.length])
    .join('');
  return string;
}
