'use client';
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { MotionDiv } from '@reshima/shared-ui';
import { Portal } from './portal';
import { Button } from './button';

export type DropdownRef = {
  open: () => void;
  close: () => void;
};

type Props = PropsWithChildren<{
  container: ReactNode;
  ariaLabel: string;
  round?: boolean;
  circle?: boolean;
  ghost?: boolean;
  tight?: boolean;
  className?: string;
  disabled?: boolean;
  secondary?: boolean;
  portal?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}>;

type Coords = {
  left: number;
  top: number;
};

const animationDuration = 100;

function getLeftCoords({
  button,
  dropdown,
}: {
  button: HTMLButtonElement;
  dropdown: HTMLDivElement;
}) {
  const buttonRect = button.getBoundingClientRect();
  const spaceRight = window.innerWidth - buttonRect.right;
  const spaceLeft = buttonRect.left;

  const dropdownWidth = dropdown.offsetWidth || 0;

  const isEnoughSpaceFromRight = spaceRight > dropdownWidth;
  const isEnoughSpaceFromLeft = spaceLeft > dropdownWidth;

  const buttonX = buttonRect.x + window.scrollX;

  const renderRight = buttonX;
  const renderLeft = buttonX - dropdownWidth + buttonRect.width;

  if (isEnoughSpaceFromLeft) {
    return renderLeft;
  }

  if (isEnoughSpaceFromRight) {
    return renderRight;
  }

  return spaceLeft > spaceRight ? renderLeft : renderRight;
}

function getTopCoords({
  button,
  dropdown,
}: {
  button: HTMLButtonElement;
  dropdown: HTMLDivElement;
}) {
  const buttonRect = button.getBoundingClientRect();
  const spaceAbove = buttonRect.top;
  const spaceBelow = window.innerHeight - buttonRect.bottom;

  const dropdownHeight = dropdown.offsetHeight || 0;

  const isEnoughSpaceAbove = spaceAbove > dropdownHeight;
  const isNotEnoughSpaceBelow = spaceBelow < dropdownHeight;

  const buttonY = buttonRect.y + window.scrollY;

  if (isNotEnoughSpaceBelow && isEnoughSpaceAbove) {
    return buttonY - dropdownHeight;
  }

  return buttonY + buttonRect.height;
}

export const Dropdown = forwardRef<DropdownRef, Props>(function Dropdown(
  {
    container,
    ariaLabel,
    round = true,
    circle,
    ghost,
    tight,
    className,
    disabled,
    secondary,
    portal = true,
    onOpen,
    onClose,
    children,
  }: Props,
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [isRequestClose, setIsRequestClose] = useState(false);
  const [coords, setCoords] = useState<Coords>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const updateCoords = useCallback(() => {
    if (!buttonRef.current || !dropdownRef.current) {
      return;
    }

    const button = buttonRef.current;
    const dropdown = dropdownRef.current;

    if (portal) {
      setCoords({
        left: getLeftCoords({ button, dropdown }),
        top: getTopCoords({ button, dropdown }),
      });
    }
  }, [portal]);

  const open = useCallback(() => {
    updateCoords();
    setIsOpen(true);
    onOpen?.();
  }, [updateCoords, onOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!isOpen || buttonRef.current?.contains(e.target as Node)) {
        return;
      }

      setIsRequestClose(true);
    }

    function onKeydown(e: KeyboardEvent) {
      if (isOpen && e.key === 'Escape') {
        setIsRequestClose(true);
      }
    }

    window.addEventListener('click', onClick);
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('keydown', onKeydown);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isRequestOpen && !isOpen) {
      setTimeout(() => {
        open();
        setIsRequestOpen(false);
      });
    }

    if (isRequestClose && isOpen) {
      close();
      setTimeout(() => setIsRequestClose(false), animationDuration);
    }
  }, [isRequestOpen, isRequestClose, isOpen, open, close]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  function Content() {
    return (
      <MotionDiv
        ref={dropdownRef}
        initial={{
          opacity: 0,
          y: -10,
        }}
        variants={{
          closed: { opacity: 0, y: -10 },
          open: { opacity: 1, y: 0 },
        }}
        transition={{ duration: animationDuration / 1000 }}
        className={classNames(
          'absolute z-20',
          'w-max p-1',
          'bg-base-100 border border-base-300 rounded-md shadow-md',
        )}
        animate={isOpen ? 'open' : 'closed'}
        style={coords}
      >
        {children}
      </MotionDiv>
    );
  }

  const showContent = isRequestOpen || isRequestClose || isOpen;

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={() =>
          isOpen ? setIsRequestClose(true) : setIsRequestOpen(true)
        }
        ariaLabel={ariaLabel}
        className={className}
        round={round}
        circle={circle}
        ghost={ghost}
        tight={tight}
        disabled={disabled}
        secondary={secondary}
      >
        {container}
      </Button>
      {portal ? (
        showContent && (
          <Portal>
            <Content />
          </Portal>
        )
      ) : (
        <div className="relative">{showContent && <Content />}</div>
      )}
    </>
  );
});
