export async function timeoutPromise<T>(
  promise: Promise<T>,
  ms: number,
): Promise<T> {
  let timeoutHandle: NodeJS.Timeout | undefined;

  const timeout$ = new Promise<never>((_, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error('Promise timeout')), ms);
  });

  try {
    const value: T = await Promise.race([promise, timeout$]);
    return value;
  } finally {
    clearTimeout(timeoutHandle);
  }
}
