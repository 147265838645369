export function isWeb(): boolean {
  return (
    !!process.env['NEXT_PUBLIC_IS_WEB_APP'] || typeof window !== 'undefined'
  );
}

export type Runtime = 'web' | 'azure' | 'firebase';

export function getRuntime(): Runtime {
  if (isWeb()) {
    return 'web';
  }

  if ('APPLICATIONINSIGHTS_CONNECTION_STRING' in process.env) {
    return 'azure';
  }

  if ('FIREBASE_CONFIG' in process.env) {
    return 'firebase';
  }

  throw new Error('Unknown runtime');
}
