'use client';
import { ReactNode, useRef, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Dropdown } from './dropdown';
import classNames from 'classnames';
import { Button } from './button';
import { StopClickPropagation } from './stop-click-propagation';

export type SelectOption<T> = {
  value: T;
  shortLabel?: ReactNode;
  longLabel: ReactNode;
  text?: string;
};

type Props<T> = {
  ariaLabel: string;
  value?: T;
  placeholder?: ReactNode;
  options: SelectOption<T>[];
  selectClassName?: string;
  optionsClassName?: string;
  optionClassName?: string;
  ghost?: boolean;
  tight?: boolean;
  disabled?: boolean;
  round?: boolean;
  chevron?: boolean;
  search?: boolean;
  secondary?: boolean;
  portal?: boolean;
  searchPlaceholder?: string;
  noResultsMessage?: ReactNode;
  onChange: (value: T) => void;
};

export function Select<T>({
  ariaLabel,
  value,
  placeholder,
  options,
  selectClassName,
  optionsClassName,
  optionClassName,
  ghost,
  tight,
  disabled,
  search,
  searchPlaceholder,
  noResultsMessage,
  secondary,
  round = true,
  chevron = true,
  portal = true,
  onChange,
}: Props<T>) {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const selectedOption = options.find((option) => option.value === value);

  const filteredOptions =
    search && searchValue
      ? options.filter((option) => option.text?.includes(searchValue))
      : options;

  return (
    <Dropdown
      ariaLabel={ariaLabel}
      ghost={ghost}
      tight={tight}
      disabled={disabled}
      round={round}
      className={selectClassName}
      secondary={secondary}
      portal={portal}
      container={
        <div className="flex items-center gap-1">
          <div>{selectedOption?.shortLabel || placeholder}</div>
          {chevron && (
            <div>
              <GoChevronDown />
            </div>
          )}
        </div>
      }
      onOpen={() => searchRef.current?.focus()}
    >
      <div className="flex flex-col gap-2">
        {search && (
          <StopClickPropagation className="w-full">
            <input
              ref={searchRef}
              className="w-full px-2 py-1 bg-base-100 border border-base-300 rounded-md"
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
          </StopClickPropagation>
        )}
        <div className={classNames('flex flex-col', optionsClassName)}>
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => {
              const isSelected = option.value === value;
              return (
                <Button
                  ghost
                  className={classNames(
                    'text-right',
                    {
                      'font-semibold bg-base-200': isSelected,
                    },
                    optionClassName,
                  )}
                  key={index}
                  onClick={() => {
                    if (!isSelected) {
                      onChange(option.value);
                    }
                  }}
                >
                  {option.longLabel}
                </Button>
              );
            })
          ) : (
            <StopClickPropagation>
              <div className="py-0.5 xs:py-1 px-2 xs:px-3">
                {noResultsMessage}
              </div>
            </StopClickPropagation>
          )}
        </div>
      </div>
    </Dropdown>
  );
}
