export enum Action {
  Initialize = 'Initialize',
  Load = 'Load',
  Click = 'Click',
  Open = 'Open',
  Close = 'Close',
  Cancel = 'Cancel',
  Delete = 'Delete',
  Join = 'Join',
  Leave = 'Leave',
  Query = 'Query',
  Rename = 'Rename',
  Check = 'Check',
  Update = 'Update',
  Reset = 'Reset',
  Callable = 'Callable',
  Fetch = 'Fetch',
  FetchNext = 'FetchNext',
  Sort = 'Sort',
  Categorize = 'Categorize',
  Recategorize = 'Recategorize',
  Serving = 'Serving',
  Calculate = 'Calculate',
  Create = 'Create',
  Upsert = 'Upsert',
  Get = 'Get',
  Dispose = 'Dispose',
  Change = 'Change',
  Disable = 'Disable',
  Listen = 'Listen',
  Restart = 'Restart',
  Terminate = 'Terminate',
  ManageCluster = 'ManageCluster',
  Request = 'Request',
  Page = 'Page',
  Count = 'Count',
}

export enum ActionModifier {
  Start = 'Start',
  End = 'End',
  Fail = 'Fail',
  NotFound = 'NotFound',
  Skip = 'Skip',
  Post = 'Post',
  Invalid = 'Invalid',
}
