export enum ProviderId {
  google = 'google',
  apple = 'apple',
}

export enum TestProviderId {
  test = 'test',
}

export type ProviderIdOrTest = ProviderId | TestProviderId;
